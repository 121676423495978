import "./App.css";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import Loading from "./assets/test.json";
import PTS from "./assets/logo-3.svg";
import Preloader from "./Components/Preload";
import Footer from "./Components/Footer";
import Content from "./Components/Content";
import Slider from "./Components/Slider";
import WhatWeDo from "./Components/WhatWeDo";
import Header from "./Components/Header";
import PopupHeader from "./Components/Header/popupHeader";

function App() {
  return (
    <div id="wrapper" className="wrapper">
      <Preloader />
      {/* <Header />
      <PopupHeader /> */}
      {/* <div style={{ marginBottom: 20 }}>
        <img alt="Logo" src={PTS} style={{ width: "20vh" }} />
      </div>
      <div>
        <Lottie animationData={Loading} style={{ width: "50vh" }} />
      </div> */}
      {/* <Slider /> */}
      <Content />
      <WhatWeDo />
      <Footer />
    </div>
  );
}

export default App;
