import React from "react";
import WhatWeDoCard from "./card";

export default function WhatWeDo() {
  return (
    <div
      id="section-our-services"
      class="brook-icon-boxes-area ptb--120 ptb-md--80 ptb-sm--80"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="brook-section-title text-center">
              <h3 class="heading heading-h3 font-large">WHAT WE OFFER?</h3>
              <div class="bkseparator--25"></div>
              <div class="title-separator w--80 color-red"></div>
            </div>
          </div>
        </div>
        <div class="row mt--70">
          <WhatWeDoCard
            title={"Informed"}
            desc={"Decision Making"}
            img={"2"}
          />
          <WhatWeDoCard
            title={"Detailed Insights"}
            img={"1"}
            desc={"in Customer Behaviour"}
          />
          <WhatWeDoCard
            title={"Enhanced"}
            img={"3"}
            desc={"Customer Journey"}
          />
          {/* <div class="col-lg-12">
            <div class="best-service-footer plr--160 plr_md--50 plr_sm--20 plr_lg--50 text-center mt--70">
              <h6 class="heading heading-h6 font-16 line-height-1-88 font-medium body-color">
                We offer innovate B2B and B2C products that are focused on
                gathering data and delivering tangible actionable information to
                stakeholders.
              </h6>
              <div class="bkseparator--45"></div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
