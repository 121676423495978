import React from "react";

export default function Content() {
  return (
    <main className="page-content" id="about-us">
      <div className="brook-what-do-area ptb--160 ptb-md--80 ptb-sm--60 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-2">
              <div className="brook-section-title wow move-up">
                <h5
                  className="heading heading-h5 yellow-color-2"
                  style={{ fontSize: 32 }}
                >
                  ABOUT US
                </h5>
                <div className="bkseparator--30"></div>
                <h3 className="heading heading-h3 line-height-1-42">
                  Optimising Mall Performance Through Data Driven Customer
                  Insight
                </h3>
              </div>
            </div>
          </div>
          {/* <div className="row mt--20 mt_sm--5 mt_md--5">
            <div className="col-lg-2"></div>
            <div className="col-lg-4">
              <div className="what-do mt--40 mt_md--20 mt_sm--5">
                <div className="basic-separator line-3"></div>
              </div>
              <div className="what-do mt--40">
                <div className="content">
                  <h5 className="heading heading-h5 yellow-color-2 wow move-up">
                    Improve
                  </h5>
                  <div className="bkseparator--20"></div>
                  <p className="bk_pra wow move-up">
                    Informed Decision Making in Retail
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <div className="what-do mt--40 mt_md--20 mt_sm--5">
                <div className="basic-separator line-3"></div>
              </div>
              <div className="what-do mt--40">
                <div className="content">
                  <h5 className="heading heading-h5 yellow-color-2 wow move-up">
                    Unique designs
                  </h5>
                  <div className="bkseparator--20"></div>
                  <p className="bk_pra wow move-up">
                    Informed Decision Making in Retail
                  </p>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>

      {/* <div className="brook-video-area bg_color--1 pb--130 pb_md--80 pb_sm--60">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-12">
              <div className="video-with-thumb text-center move-up wow">
                <div className="thumb">
                  <img src="img/about/macbook-02.png" alt="video images" />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12 mt_md--40 mt_sm--40">
              <div className="video-content move-up wow">
                <h3 className="heading heading-h3 line-height-1-42">
                  We are are Proptech company delivering products and solutions
                  for commercial real estate market in a SaaS model.
                </h3>
                <div className="bkseparator--25"></div>
                <p className="bk_pra">
                  Brook presents your services with flexible, convenient and
                  multipurpose layouts. You can select your favorite layouts &
                  elements for particular projects with unlimited customization
                  possibilities.
                </p>
                <div className="bkseparator--40"></div>
                <a className="moredetails-btn yellow-color-2" href="#">
                  <span>More details</span>{" "}
                  <i className="fa fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="brook-stratagy-area bg_color--7">
        <div className="row">
          <div className="col-lg-6">
            <div className="bk-stratagy-content max-width--600 float-end pt--120 pb--120 pr--160 pr_lg--30 pl_lg--30 pr_md--50 pl_md--50 pr_sm--30 pl_sm--30">
              <div className="content">
                <h3 className="heading heading-h3 line-height-1-42 yellow-color-2 wow move-up text-white">
                  Brand strategy and digital creation.
                </h3>
                <div className="bkseparator--30"></div>
                <p className="bk_pra font-16 line-height-1-87 pr--30 wow move-up text-white">
                  Our quality of service assessment involves controlling and
                  managing resources to ensure customers' satisfaction.
                </p>
                <div className="bkseparator--40"></div>

                <div className="bk-list--2 wow move-up">
                  <div className="list-header with-ckeck check-yellow-color-2">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h6 className="heading heading-h5 text-white">
                        Business Architecture
                      </h6>
                    </div>
                  </div>
                  <div className="list-header with-ckeck check-yellow-color-2">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h6 className="heading heading-h5 text-white">
                        Cognitive Architecture
                      </h6>
                    </div>
                  </div>
                  <div className="list-header with-ckeck check-yellow-color-2">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h6 className="heading heading-h5 text-white">
                        Interior Architecture
                      </h6>
                    </div>
                  </div>

                  <div className="list-header with-ckeck check-yellow-color-2">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h6 className="heading heading-h5 text-white">
                        Landscape Architecture
                      </h6>
                    </div>
                  </div>

                  <div className="list-header with-ckeck check-yellow-color-2">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h6 className="heading heading-h5 text-white">
                        UI/UX designs
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bk-stratagy-thumb bg_image--25 ptb--180 h-100"></div>
          </div>
        </div>
      </div> */}

      {/* <div className="brook-call-to-action bg_color--13 ptb--70 bg-as-text2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-12 col-12">
              <div className="call-content text-center text-md-start text-center">
                <h4 className="heading heading-h4 text-white">
                  Sign up for new updates from us.
                </h4>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-12">
              <div className="text-center text-md-end text-center mt_sm--30 mt_md--40 contact-form contact-form--4 yellow-color-2">
                <form action="#">
                  <div className="input-box">
                    <input type="email" placeholder="Your e-mail" />
                    <button>Subscribe</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </main>
  );
}
