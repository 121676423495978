import React from "react";

export default function WhatWeDoCard(props) {
  const { title, desc, img } = props;

  console.log("url(img/" + img + ".png");
  return (
    <div
      class="col-lg-4 col-md-6 col-sm-6 col-12 wow move-up"
      style={{ marginTop: 5, marginBottom: 5 }}
    >
      <div class="single-svg-icon-box business-icon-box">
        <div
          class="grid-overlay"
          style={{
            backgroundImage: "url(img/" + img + ".png",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,.6)",
          }}
        />
        <div class="inner">
          <div class="content text-center pt--150">
            <h5 class="heading heading-h5">{title}</h5>
            <p>{desc}</p>
          </div>
          {/* <div class="br-icon-box-btn">
            <a class="text-center" href="#">
              Learn More
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
