import React from "react";

export default function Footer() {
  return (
    <footer className="page-footer" style={{ backgroundColor: "#00003B" }}>
      <div className="bk-footer-inner pt--30 pb--30">
        <div className="container" style={{ maxWidth: "80%" }}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 pt--40">
              <div className="footer-widget text-var--2">
                <div className="logo">
                  <a href="index.html">
                    <img
                      src="img/retail-new.svg"
                      alt="Retail Insights B.V."
                      style={{ width: 200, height: 100 }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
              <div className="footer-widget text-var--2 menu--contact">
                <h2 className="widgettitle">Contact</h2>
                <div className="footer-address">
                  <div className="bk-hover">
                    <p>
                      Parnassusweg 819, 1082 LZ Amsterdam, <br />
                      The Netherlands
                    </p>
                    <p>
                      <a href="mailto:info@retailinsights.eu">info@retailinsights.eu</a>
                    </p>
                    <p>
                      <a href="tel:+31208203900">(+31) 2 08203900</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
